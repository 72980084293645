<template>
  <div class="customNoteToMix">
    <h1>销售品名转比例设置</h1>
    <el-form :inline="true">
      <el-form-item label="日期">
        <el-date-picker :clearable="false" style="width: 130px;" v-model="searchParam.useDate" type="date" @change="commitFindList" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="add()">新建</el-button>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
          <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
          <el-button type="success" :disabled="!(show.selected.length >= 1)" @click="show.copyTo = true">复制</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="55" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">详情</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="customNote" label="销售品名" show-overflow-tooltip />
      <el-table-column prop="storage" label="对应仓储" show-overflow-tooltip />
      <el-table-column prop="mixList" label="混合配比" show-overflow-tooltip>
        <template #default="scope">
          <span v-for="(item, index) in scope.row.mixList" :key="item.product">
            <el-text style="margin: 0 10px;" v-if="index != 0">:</el-text>
            <el-text>{{ item.product + '[' + item.mixNumber + ']' }}</el-text>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <el-dialog v-model="show.copyTo" title="确认复制" width="500">
    <el-form>
      <el-form-item label="将选中配比复制到：">
        <el-date-picker :clearable="false" v-model="show.copyToDate" type="date" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="copyTo()">复制</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  list: [],
})

const show = reactive({
  selected: [],
  copyTo: false,
  copyToDate: new Date(),
})

const searchParam = reactive({
  useDate: new Date(),
})
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'customNoteToMix')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'customNoteToMix', null)
}

const commitFindList = () => {
  const useDate = util.parseTime(searchParam.useDate, '{y}-{m}-{d}')
  api.get('/backend/customNoteToMix/all', { params: { useDate } }).then(res => {
    data.list = res.list
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'customNoteToMix', searchParam)
  cache.setObject(cache.keys.pageParam + 'customNoteToMixDetail', null)
  router.push('/customNoteToMixDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'customNoteToMix', searchParam)
  const customNoteToMix = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'customNoteToMixDetail', customNoteToMix)
  router.push('/customNoteToMixDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(customNoteToMix => customNoteToMix.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/customNoteToMix/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

const copyTo = () => {
  const ids = tabRef.value.getSelectionRows().map(customNoteToMix => customNoteToMix.id)
  const copyToDate = util.parseTime(show.copyToDate, '{y}-{m}-{d}')
  api.post('/backend/customNoteToMix/copyToDate', { ids: ids, copyToDate: copyToDate }).then(() => {
    ElMessage.success('操作成功')
    commitFindList()
    show.copyTo = false
  })
}
</script>

<style lang="less"></style>